import { useEffect, useState, createContext } from "react";
import { HiVideoCamera } from "react-icons/hi";
import { TabPanel } from "../../components/TabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { DashboardClienti } from "./DashboardClienti";
import { PayrollClienti } from "./PayrollClienti";
import {
  MdBarChart,
  MdCheck,
  MdContactPhone,
  MdPayments,
} from "react-icons/md";
import { toast } from "react-toastify";
import { FaFileSignature } from "react-icons/fa6";
import { Button } from "../../components/Button";
import { AssunzioniClienti } from "./AssunzioniClienti";
import { RecapitiClienti } from "./RecapitiClienti";
import {
  HiOutlineChartPie,
  HiOutlineCheck,
  HiOutlineClipboardDocumentCheck,
  HiOutlineCurrencyEuro,
  HiOutlineGlobeAlt,
  HiOutlinePlus,
  HiOutlineRectangleGroup,
  HiWallet,
} from "react-icons/hi2";
import { PrincipalButton } from "../../components/PrincipalButton";
import api from "../../api/api";
import { Fatturazione, FatturazioneClienti } from "./FatturazioneClienti";
import { useBlocker } from "react-router-dom";
import { createPortal } from "react-dom";
import Modal from "../../components/Modal";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

export const DettaglioClienti = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cliente, setCliente] = useState(location.state.cliente);
  const [tab, setTab] = useState(1);
  const [block, setBlock] = useState(false);
  const [modal, setModal] = useState(false);

  const handleBeforeUnload = (event) => {
    // per il refresh
    event.preventDefault();
    event.returnValue =
      "Sono state rilevate modifiche,  sei sicuro di voler ricaricare la pagina?"; // Chrome requires returnValue to be set
  };

  const [tabs, setTabs] = useState([
    {
      icon: <HiOutlineChartPie size={26} className="tab-icon" />,
      text: "Dashboard",
      id: 1,
    },
    {
      icon: <HiOutlineCurrencyEuro size={26} className="tab-icon" />,
      text: "Payroll",
      id: 2,
    },
    {
      icon: <HiOutlineClipboardDocumentCheck size={26} className="tab-icon" />,
      text: "Assunzioni",
      id: 3,
    },
    {
      icon: <HiOutlineGlobeAlt size={26} className="tab-icon" />,
      text: "Recapiti/altri servizi",
      id: 4,
    },
    {
      icon: <HiWallet size={26} className="tab-icon" />,
      text: "Fatturazione",
      id: 5,
    },
  ]);

  const handleSave = async () => {
    try {
      const aziende = await api.put(`/aziende`, { azienda: cliente });
      setBlock(false);
      toast.success("SALVATAGGIO OK");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    } catch {
      toast.error("ERRORE NEL SALVATAGGIO");
    }
  };

  const handleChange = (e) => {
    let c = { ...cliente };
    c[e.target.name] = e.target.value;
    setCliente(c);
    setBlock(true);
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  const handleChangeSelect = (e, name) => {
    let c = { ...cliente };
    c[name] = e.target.value;
    setCliente(c);
    setBlock(true);
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  const handleChangeCheckbox = (e, name) => {
    let c = { ...cliente };
    c[name] = e.target.checked ? 1 : 0;
    setCliente(c);

    setBlock(true);
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      block && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setModal(true);
    }
  }, [blocker]);

  /*const [content, setContent] = useState([
    { child: <DashboardClienti  cliente={cliente} handleSave={handleSave} handleChange={handleChange}/>, id: 1 },
    { child: <PayrollClienti cliente={cliente} />, id: 2 },
    { child: <AssunzioniClienti cliente={cliente} />, id: 3 },
    { child: <RecapitiClienti cliente={cliente} />, id: 4 },
  ]);*/

  const content = [
    {
      child: (
        <DashboardClienti
          cliente={cliente}
          handleSave={handleSave}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
        />
      ),
      id: 1,
    },
    {
      child: (
        <PayrollClienti
          cliente={cliente}
          handleSave={handleSave}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
        />
      ),
      id: 2,
    },
    {
      child: (
        <AssunzioniClienti
          cliente={cliente}
          handleChangeCheckbox={handleChangeCheckbox}
          handleSave={handleSave}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
        />
      ),
      id: 3,
    },
    {
      child: (
        <RecapitiClienti
          cliente={cliente}
          handleChangeCheckbox={handleChangeCheckbox}
          handleSave={handleSave}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
        />
      ),
      id: 4,
    },
    {
      child: (
        <FatturazioneClienti
          cliente={cliente}
          handleChangeCheckbox={handleChangeCheckbox}
          handleSave={handleSave}
          handleChange={handleChange}
          handleChangeSelect={handleChangeSelect}
        />
      ),
      id: 5,
    },
  ];

  useEffect(() => setCliente(location.state.cliente), []);

  return (
    <>
      {modal &&
        createPortal(
          <Modal
            isOpen={modal}
            closeButton={() => setModal(false)}
            headerText={"ATTENZIONE!"}
            rightButtons={
              <Button
                className="success-button"
                size="md"
                text={"Conferma"}
                onClick={() => blocker.proceed?.()}
                icon={<AiOutlineCheck size={20} />}
              />
            }
            leftButtons={
              <Button
                className="error-button"
                size="md"
                text={"Annulla"}
                onClick={() => setModal(false)}
                icon={<AiOutlineClose size={20} />}
              />
            }
          >
            <div className="el-container">
              Sono state rilevate modifiche, sei sicuro di voler lasciare la
              pagina?
            </div>
          </Modal>,
          document.getElementById("portalModal")
        )}
      <div className="page-container">
        <TabPanel
          title={
            cliente?.codice_fiscale +
            " - " +
            cliente?.codice_univoco +
            (cliente?.codice_parcellazione
              ? " - " +
                cliente.tipologia_fatturazione +
                cliente.codice_parcellazione +
                " - "
              : " - codice_parce - ") +
            cliente?.ragione_sociale
          }
          tab={tab}
          setTab={setTab}
          tabs={tabs}
          content={content}
        />
      </div>
      {/* <div className="flex w-full min-h-fit justify-end">
          <Button
            className="success-button"
            text="salva"
            type="button"
            icon={<MdCheck className="button-icon" />}
            onClick={async () => {
              console.log("salva");
            }}
          />
        </div> */}
    </>
  );
};
