import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { MdEdit, MdDelete, MdAdd, MdArrowBack } from "react-icons/md";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import { SlOptionsVertical } from "react-icons/sl";
import Modal from "../../components/Modal";
import {
  HiMiniArrowDownTray,
  HiOutlineCheck,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineXMark,
  HiPlus,
} from "react-icons/hi2";
import { PrincipalButton } from "../../components/PrincipalButton";
import api from "../../api/api";
import { CustomCheckbox } from "../../components/Checkbox";

export const Parcellazione = ({ screenSize, f24, dataF24 }) => {
  const [dataA, setDataA] = useState(moment(new Date()).format("YYYY-MM"));
  const [dataDa, setDataDa] = useState(
    moment(new Date()).subtract(6, "months").format("YYYY-MM")
  );
  const [parcellazione, setParcellazione] = useState([]);
  const [prestazioni, setPrestazioni] = useState([]);
  const [tipologieFatturazione, setTipologiaFatturazione] = useState([]);
  const [settori, setSettori] = useState([]);
  const [metodiFatturazione, setMetodiFatturazione] = useState([]);
  const [appartenenza, setAppartenenza] = useState([]);
  const [prestazioneSel, setPrestazioneSel] = useState(null);
  const [tipologia, setTipologia] = useState(null);

  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              className="edit-button "
              type="button"
              icon={<MdEdit className="button-icon" />}
              onClick={async () => {
                setTipologia("modifica");
                setPrestazioneSel(params.row);
              }}
            />
            <Button
              className="delete-button "
              type="button"
              icon={<MdDelete className="button-icon" />}
              onClick={async () => {
                setTipologia("elimina");
                setPrestazioneSel(params.row);
              }}
            />
          </>
        );
      },
    },
    {
      field: "codice",
      headerName: "Codice",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "descrizione_voce_ago",
      headerName: "Descrizione Ago",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "descrizione_voce_crm",
      headerName: "Descrizione Crm",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "dettaglio",
      headerName: "Dettaglio fatturazione",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  useEffect(() => {}, [screenSize]);

  const loadPrestazioni = async () => {
    const prest = await api.get("/prestazioni");
    setPrestazioni(prest.data);
  };
  const loadTipologieFatturazioni = async () => {
    const prest = await api.get("/prestazioni/dettagli");
    setAppartenenza(prest.data.appartenenza);
    setMetodiFatturazione(prest.data.metodo_fatturazione);
    setSettori(prest.data.settore);
    setTipologiaFatturazione(prest.data.tipologia_fatturazione);
  };

  useEffect(() => {
    //carico clienti subito
    loadPrestazioni();
    loadTipologieFatturazioni();
    //
  }, []);

  useEffect(() => {
    if (prestazioneSel && tipologia == "modifica") {
      document.getElementById("editPrestazione").click();
    }
    if (prestazioneSel && tipologia == "elimina") {
      document.getElementById("deletePrestazione").click();
    }
  }, [prestazioneSel]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex justify-center md:justify-end md:mx-0 mx-auto">
            <ModalPrestazione
              tipologia={"inserimento"}
              appartenenza={appartenenza}
              settori={settori}
              metodiFatturazione={metodiFatturazione}
              tipologieFatturazione={tipologieFatturazione}
              prestazioni={prestazioni}
              loadPrestazioni={loadPrestazioni}
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="el-container h-fit ">
      <ModalPrestazione
        tipologia={"modifica"}
        appartenenza={appartenenza}
        settori={settori}
        metodiFatturazione={metodiFatturazione}
        tipologieFatturazione={tipologieFatturazione}
        prestazioni={prestazioni}
        prestazioneSel={prestazioneSel}
        loadPrestazioni={loadPrestazioni}
      />
      <ModalPrestazione
        tipologia={"elimina"}
        appartenenza={appartenenza}
        settori={settori}
        metodiFatturazione={metodiFatturazione}
        tipologieFatturazione={tipologieFatturazione}
        prestazioni={prestazioni}
        prestazioneSel={prestazioneSel}
        loadPrestazioni={loadPrestazioni}
      />

      <div className="w-full md:h-[70vh] h-[70vh] relative el-container">
        <DataGridPro
          density="compact"
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
            footer: CustomFooterPersonalized,
          }}
          //loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={prestazioni}
          getRowId={(r) => r.codice}
        />
      </div>
    </div>
  );
};

const ModalPrestazione = ({
  tipologia,
  appartenenza,
  settori,
  metodiFatturazione,
  prestazioneSel,
  prestazioni,
  loadPrestazioni,
  tipologieFatturazione,
}) => {
  const [open, setOpen] = useState(false);
  const [prestazione, setPrestazione] = useState(null);

  const handleChangeSelect = (e, name) => {
    let p = { ...prestazione };
    p[name] = e.target.value;
    setPrestazione(p);
  };
  const handleChangeCheckbox = (e, name) => {
    let p = { ...prestazione };
    p[name] = e.target.checked ? 1 : 0;
    setPrestazione(p);
  };

  const handleOpenModal = () => {
    if (tipologia === "modifica" || tipologia === "elimina") {
      setPrestazione(prestazioneSel);
    } else {
      setPrestazione({
        codice: 0,
        descrizione_voce_ago: "",
        descrizione_voce_crm: "",
        tipologia_fatturazione: 0,
        appartenenza: 0,
        settore: 0,
        metodo_fatturazione: 0,
        importo_default: 0,
        richiesta: "",
        attivo: 1,
      });
    }
    setOpen(true);
  };
  const handleChange = (e) => {
    let p = { ...prestazione };
    p[e.target.name] = e.target.value;
    setPrestazione(p);
  };
  const handleSave = async () => {
    try {
      if (tipologia === "inserimento") {
        let check = prestazioni.filter((el) => el.codice == prestazione.codice);

        if (check.length > 0) {
          toast.error("Attenzione, codice già esistente");
        } else {
          await api.post("/prestazioni", { prestazione: prestazione });
          toast.success("Prestazione inserita con successo");
        }
      } else if (tipologia === "modifica") {
        await api.put("/prestazioni", { prestazione: prestazione });
        toast.success("Prestazione modificata con successo");
      } else {
        await api.delete("/prestazioni" + prestazione.ID);
        toast.success("Prestazione eliminata con successo");
      }
      loadPrestazioni();
      setOpen(false);
    } catch {
      toast.error("Errore durante il salvataggio");
    }
  };

  return (
    <>
      {tipologia === "inserimento" ? (
        <Button
          className="success-button"
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      ) : tipologia === "modifica" ? (
        <Button
          className="hidden"
          id="editPrestazione"
          type="button"
          icon={<MdEdit className="button-icon " />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      ) : (
        <Button
          className="hidden"
          id="deletePrestazione"
          type="button"
          icon={<MdEdit className="button-icon " />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      )}
      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={`${tipologia} prestazioni`}
        size={"medium"}
        leftButtons={
          <Button
            className="principal-button"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        {" "}
        {tipologia == "elimina" ? (
          <>
            Sei sicuro di voler eliminare la prestazione{" "}
            {prestazione?.codice + " " + prestazione?.descrizione_voce_ago}
          </>
        ) : (
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-2 md:col-span-1">
              <Input
                type="number"
                label={"Codice"}
                name="codice"
                onChange={(e) => handleChange(e)}
                value={prestazione?.codice}
                disabled={tipologia === "modifica"}
                maxLength={11}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Input
                type="text"
                label={"Descrizione Ago"}
                name="descrizione_voce_ago"
                onChange={(e) => handleChange(e)}
                value={prestazione?.descrizione_voce_ago}
                disabled={false}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Input
                type="text"
                label={"Descrizione Crm"}
                name="descrizione_voce_crm"
                onChange={(e) => handleChange(e)}
                value={prestazione?.descrizione_voce_crm}
                disabled={false}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Select
                label={"Tipologia Fatturazione"}
                placeholder="Seleziona..."
                options={tipologieFatturazione}
                value={prestazione?.tipologia_fatturazione}
                onChange={(e) =>
                  handleChangeSelect(e, "tipologia_fatturazione")
                }
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Select
                label={"Appartenenza"}
                placeholder="Seleziona..."
                options={appartenenza}
                value={prestazione?.appartenenza}
                onChange={(e) => handleChangeSelect(e, "appartenenza")}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Select
                label={"Settore"}
                placeholder="Seleziona..."
                options={settori}
                value={prestazione?.settore}
                onChange={(e) => handleChangeSelect(e, "settore")}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Select
                label={"Metodo Fatturazione"}
                placeholder="Seleziona..."
                options={metodiFatturazione}
                value={prestazione?.metodo_fatturazione}
                onChange={(e) => handleChangeSelect(e, "metodo_fatturazione")}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <Input
                type="text"
                label={"importo default"}
                name="importo_default"
                onChange={(e) => handleChange(e)}
                value={prestazione?.importo_default}
                disabled={false}
              />
            </div>
            <div className="col-span-2 ">
              <Input
                type="text"
                label={"Dettaglio richiesta in fatturazione"}
                name="richiesta"
                onChange={(e) => handleChange(e)}
                value={prestazione?.richiesta}
                disabled={false}
              />
            </div>
            <div className="col-span-2 ">
              <CustomCheckbox
                label="Attiva"
                name="attivo"
                checked={prestazione?.attivo}
                onChange={(e) => handleChangeCheckbox(e, "attivo")}
                value={prestazione?.attivo}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
