import React, { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../components/Sidebar";

import {
  HiOutlineBell,
  HiOutlineCalculator,
  HiOutlineChartBar,
  HiOutlineFolder,
  HiOutlineHome,
  HiOutlineHomeModern,
  HiOutlineInboxStack,
  HiOutlinePower,
  HiOutlineUserGroup,
} from "react-icons/hi2";
import { axiosPrivate } from "../api/api";
import { HiOutlineDatabase } from "react-icons/hi";
import { knowAcces } from "../utils/utils";

export const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const [screenSize, getDimension] = useState(window.innerWidth);

  const [links, setLinks] = useState([
    {
      id: 1,
      href: "/Home",
      icon: <HiOutlineHome className="li-icon" />,
      text: "Home",
      visibility: true,
    },
    {
      id: 2,
      href: "/Clienti",
      icon: <HiOutlineHomeModern className="li-icon" />,
      text: "Clienti",
      visibility: true,
    },
    {
      id: 3,
      href: "/F24",
      icon: <HiOutlineInboxStack className="li-icon" />,
      text: "F24",
      visibility: knowAcces([
        "paghe",
        "team_leader",
        "admin",
        "segreteria",
        "f24",
      ]),
    },
    {
      id: 5,
      href: "/Parcellazione",
      icon: <HiOutlineCalculator className="li-icon" />,
      text: "Parcellazione",
      visibility: knowAcces(["admin"]),
    },
    {
      id: 6,
      href: "/Newsletter",
      icon: <HiOutlineBell className="li-icon" />,
      text: "Newsletter",
      visibility: knowAcces(["admin"]),
    },
    {
      id: 7,
      href: "/Referenti",
      icon: <HiOutlineUserGroup className="li-icon" />,
      text: "Referenti",
      visibility: knowAcces(["admin", "team_leader"]),
    },
    {
      id: 8,
      href: "/Statistiche",
      icon: <HiOutlineChartBar className="li-icon" />,
      text: "Statistiche",
      visibility: knowAcces(["admin", "team_leader"]),
    },
    {
      id: 9,
      href: "/Anagrafiche",
      icon: <HiOutlineDatabase className="li-icon" />,
      text: "Anagrafiche",
      visibility: knowAcces(["admin", "team_leader"]),
    },
    /* {
			id: 9 ,
			href: '/',
			//icon: <HiOutlineArrowRightOnRectangle className="li-icon" />,
			icon: <HiOutlinePower className='li-icon' />,
			text: 'Logout',
			async func() {
        try {
          await axiosPrivate.get('logout');
        } catch (error) {
          console.error(error);
        }
      },
		}, */
  ]);

  const setDimension = () => {
    getDimension(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
      if (screenSize <= "1024") {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };
  }, [screenSize]);

  useEffect(() => {
    if (collapsed) {
      sessionStorage.setItem("sidebar", "80px");
    } else {
      sessionStorage.setItem("sidebar", "250px");
    }
  }, [collapsed]);

  return (
    <>
      <ToastContainer />
      <Sidebar links={links} setLinks={setLinks} />
    </>
  );
};
