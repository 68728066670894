import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { MdEdit, MdDelete, MdAdd, MdArrowBack } from "react-icons/md";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
import { SlOptionsVertical } from "react-icons/sl";
import Modal from "../../components/Modal";
import {
  HiMiniArrowDownTray,
  HiOutlineCheck,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineXMark,
  HiPlus,
} from "react-icons/hi2";
import { PrincipalButton } from "../../components/PrincipalButton";
import api from "../../api/api";
import { CustomCheckbox } from "../../components/Checkbox";

export const NewsletterExtraLista = ({ screenSize }) => {
  const [referenti, setReferenti] = useState([]);
  const [referenteSel, setReferenteSel] = useState(null);
  const [liste, setListe] = useState([]);
  const [tipologia, setTipologia] = useState(null);
  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              className="edit-button "
              type="button"
              icon={<MdEdit className="button-icon" />}
              onClick={async () => {
                setTipologia("modifica");
                setReferenteSel(params.row);
              }}
            />
            <Button
              className="delete-button "
              type="button"
              icon={<MdDelete className="button-icon" />}
              onClick={async () => {
                setTipologia("elimina");
                setReferenteSel(params.row);
              }}
            />
          </>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "riferimento",
      headerName: "Riferimento",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  useEffect(() => {}, [screenSize]);

  const loadReferenti = async () => {
    const prest = await api.get("/newsletter_lista_extra");
    setReferenti(prest.data);
  };

  const loadListe = async () => {
    const elIb = await api.get(`/liste`);
    setListe(elIb.data);
  };

  useEffect(() => {
    //carico clienti subito
    loadReferenti();
    loadListe();
    //
  }, []);

  useEffect(() => {
    if (referenteSel && tipologia == "modifica") {
      document.getElementById("editExtra").click();
    }
    if (referenteSel && tipologia == "elimina") {
      document.getElementById("deleteExtra").click();
    }
  }, [referenteSel, tipologia]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex justify-center md:justify-end md:mx-0 mx-auto">
            <ModalPrestazione
              tipologia={"inserimento"}
              loadReferenti={loadReferenti}
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  return (
    <div className="el-container h-fit ">
      <ModalPrestazione
        tipologia={"modifica"}
        referenteSel={referenteSel}
        loadReferenti={loadReferenti}
        liste={liste}
      />
      <ModalPrestazione
        tipologia={"elimina"}
        referenteSel={referenteSel}
        loadReferenti={loadReferenti}
        liste={liste}
      />

      <div className="w-full md:h-[70vh] h-[70vh] relative el-container">
        <DataGridPro
          density="compact"
          slots={{
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
            footer: CustomFooterPersonalized,
          }}
          //loading={loading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          rows={referenti}
          getRowId={(r) => r.ID}
        />
      </div>
    </div>
  );
};

const ModalPrestazione = ({
  tipologia,
  referenteSel,
  loadReferenti,
  liste,
}) => {
  const [open, setOpen] = useState(false);
  const [referente, setReferente] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [listeAssociate, setListeAssociate] = useState(null);
  const [listaSel, setListaSel] = useState([]);

  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",

      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="hidden md:flex flex-nowrap gap-2">
              <Button
                className="delete-button"
                type="button"
                icon={<HiOutlineTrash className="button-icon" />}
                onClick={async () => {
                  removeFromLista(params.row.id_lista);
                }}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <Button
            className="principal-button "
            text="Aggiungi"
            type="button"
            icon={<HiPlus className="button-icon" />}
            onClick={() => {
              //setListaSel(true)
              setOpenModal(true);
            }}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  const loadListeAssociate = async () => {
    try {
      let resp = await api.get(
        `/newsletter_lista_extra/associate/${referenteSel.ID}`
      );
      setListeAssociate(resp.data);
    } catch {
      toast.error("Errore durante il caricamente");
    }
  };
  const handleOpenModal = async () => {
    if (tipologia === "modifica" || tipologia == "elimina") {
      setReferente(referenteSel);
      await loadListeAssociate();
    } else {
      setReferente({
        name: "",
      });
    }
    setOpen(true);
  };
  const handleChange = (e) => {
    let r = { ...referente };
    r[e.target.name] = e.target.value;
    setReferente(r);
  };
  const handleSave = async () => {
    try {
      if (tipologia === "inserimento") {
        await api.post("/newsletter_lista_extra", { lista: referente });

        toast.success("Referente inserita con successo");
      } else if (tipologia === "modifica") {
        await api.put("/newsletter_lista_extra", { lista: referente });
        toast.success("Referente modificata con successo");
      } else {
        await api.delete("/newsletter_lista_extra/" + referente.ID);
        toast.success("Referente eliminato con successo");
      }
      loadReferenti();
      setOpen(false);
    } catch {
      toast.error("Errore durante il salvataggio");
    }
  };

  const handleAssocia = async () => {
    try {
      let savePayroll = await api.post("/newsletter_lista_extra/associate", {
        ref: referenteSel.ID,
        lista: listaSel,
      });

      toast.success("associato con successo");

      loadListeAssociate();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };

  const removeFromLista = async (id) => {
    try {
      let savePayroll = await api.delete(`/newsletter_lista_extra/associa`, {
        data: {
          ref: referenteSel.ID,
          lista: id,
        },
      });

      toast.success("rimosso con successo");

      loadListeAssociate();
    } catch {
      toast.error("errore durante il salvataggio");
    }
  };

  return (
    <>
      {tipologia === "inserimento" ? (
        <Button
          className="success-button"
          text="Aggiungi"
          type="button"
          icon={<HiPlus className="button-icon" />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      ) : tipologia == "modifica" ? (
        <Button
          className="hidden"
          id="editExtra"
          type="button"
          icon={<MdEdit className="button-icon " />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      ) : (
        <Button
          className="hidden"
          id="deleteExtra"
          type="button"
          icon={<MdEdit className="button-icon " />}
          onClick={async () => {
            handleOpenModal();
          }}
        />
      )}
      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={`${tipologia} Referente`}
        size={"medium"}
        leftButtons={
          <Button
            className="principal-button"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSave();
            }}
          />
        }
      >
        {tipologia == "elimina" ? (
          <>Sei sicuro di voler eliminare il contatto {referente?.email}</>
        ) : (
          <>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-1 ">
                <Input
                  type="text"
                  label={"Referente"}
                  name="nome"
                  onChange={(e) => handleChange(e)}
                  value={referente?.nome}
                />
              </div>
              <div className="col-span-1 ">
                <Input
                  type="text"
                  label={"Email"}
                  name="email"
                  onChange={(e) => handleChange(e)}
                  value={referente?.email}
                />
              </div>
              <div className="col-span-2">
                <Input
                  type="text"
                  label={"Riferimento"}
                  name="riferimento"
                  onChange={(e) => handleChange(e)}
                  value={referente?.riferimento}
                />
              </div>
              {tipologia === "modifica" && (
                <div className="col-span-2 h-[45vh]">
                  Liste Newsletter
                  <DataGridPro
                    density="compact"
                    slots={{
                      toolbar: CustomToolbar,
                      loadingOverlay: LinearProgress,
                      footer: CustomFooterPersonalized,
                    }}
                    //loading={loading}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    columns={columns}
                    rows={listeAssociate}
                    getRowId={(el) => el.id}
                  />
                </div>
              )}
            </div>
            <Modal
              isOpen={openModal}
              closeButton={() => setOpenModal(false)}
              headerText={`Associa lista`}
              leftButtons={
                <Button
                  className="principal-button z-[110]"
                  text={tipologia === "elimina" ? "Elimina" : "Associa"}
                  type="button"
                  icon={<HiOutlineCheck className="button-icon" />}
                  onClick={async () => {
                    handleAssocia();
                  }}
                />
              }
            >
              <div className="col-span-2 md:col-span-1">
                <Select
                  type="text"
                  label={"Lista"}
                  name="tipologia_servizio_altro"
                  onChange={(e) => setListaSel(e.target.value)}
                  value={listaSel}
                  placeholder="Seleziona..."
                  options={liste?.filter(
                    (el) =>
                      listeAssociate?.map((el) => el.id).indexOf(el.id) == -1
                  )}
                  disabled={false}
                />
              </div>
            </Modal>
          </>
        )}
      </Modal>
    </>
  );
};
