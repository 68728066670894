import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { MdEdit, MdDelete, MdAdd, MdArrowBack } from "react-icons/md";
import { toast } from "react-toastify";
import { Button } from "../components/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";

import ClickAwayListener from "@mui/material/ClickAwayListener";

import { CustomFooterPersonalized } from "../components/DataGrid/CustomFooterPersonalized";
import { SlOptionsVertical } from "react-icons/sl";
import Modal from "../components/Modal";
import {
  HiMiniArrowDownTray,
  HiOutlineCheck,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineXMark,
} from "react-icons/hi2";
import { PrincipalButton } from "../components/PrincipalButton";
import api from "../api/api";
import ExportCliente from "./Clienti/ExportCliente";
import { createPortal } from "react-dom";
import { knowAcces } from "../utils/utils";
import moment from "moment";
export const Clienti = ({ screenSize }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const anchorRef = useRef();
  const actionsButton = useRef();
  const [buttonVisible, setButtonVisible] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [rowSel, setRowSel] = useState({});

  const [idRow, setIdRow] = useState();

  const [loading, setLoading] = useState(false);
  const [aziende, setAziende] = useState([]);

  useEffect(() => {}, [screenSize]);

  const handleToggleButton = () => {
    setButtonVisible(!buttonVisible);
  };

  const columns = [
    {
      field: "Azioni",
      headerName: "Azioni",
      width: screenSize > "768" ? 150 : 70,
      headerAlign: "center",
      align: "center",
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <div className="hidden md:flex flex-nowrap gap-2">
              {/* <Button
								className='delete-button'
								type='button'
								icon={<HiOutlineTrash className='button-icon' />}
								onClick={async () => {
									await setRowSel(params.row);
									setOpenDelete(true);
								}}
							/> */}
              <Button
                className="edit-button"
                type="button"
                icon={<MdEdit className="button-icon" />}
                onClick={async () => {
                  navigate("/DettaglioClienti", {
                    state: {
                      cliente: params.row,
                      nuovo: false,
                      clienti: aziende,
                    },
                  });
                }}
              />
            </div>
            <div className=" flex md:hidden md:flex-nowrap flex-wrap gap-2 w-full lg:w-2/3 float-right">
              <Button
                onClick={(e) => {
                  //imposto l'id della riga selezionata per fare comparire solo lei
                  setIdRow(params.row.id);
                  //rilevo l'altezza della riga
                  var rect = e.target.getBoundingClientRect();
                  //imposto altezza al Ref
                  actionsButton.current = rect.top;
                  handleToggleButton();
                }}
                className="relative flex items-center justify-center uppercase font-semibold md:font-bold text-white px-3 md:px-5 md:py-2.5 py-2 text-xs md:text-base rounded-lg text-center bg-success-100 hover:bg-success-200 focus:outline-none focus:ring-success-200 focus:ring-0 w-full"
                size="md"
                icon={
                  <SlOptionsVertical
                    className={`button-icon duration-500 transition-all text-miller-300 dark:text-miller-500`}
                  />
                }
              />
              {buttonVisible ? (
                <>
                  <ClickAwayListener onClickAway={handleToggleButton}>
                    <div
                      className={`absolute justify-end py-2 flex flex-wrap w-fit bg-white rounded-md shadow-sm shadow-slate-700 left-16 top-[${actionsButton.current}px]`}
                      ref={anchorRef}
                      id={params.row.id}
                      style={{
                        display: idRow === params.row.id ? "block" : "none",
                      }}
                    >
                      <Button
                        icon={<HiOutlineTrash className="button-icon" />}
                        className="nested-button"
                        text={"Elimina"}
                        onClick={async () => {
                          await setRowSel(params.row);
                          setOpenDelete(true);
                        }}
                      />
                      <Button
                        icon={<MdEdit className="button-icon" />}
                        className="nested-button"
                        text={"Modifica"}
                        onClick={() => {
                          navigate("/DettaglioClienti", {
                            state: { cliente: params.row, nuovo: false },
                          });
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: "codice_univoco",
      headerName: "Cod. Univoco",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "codice_fiscale",
      headerName: "Cod. Fiscale",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ragione_sociale",
      headerName: "Ragione Sociale",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "gruppo_cliente",
      headerName: "Gruppo Aziende",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "media_cedolini",
      headerName: "N° Medio Cedolini",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "referente_attivo_payroll",
      headerName: "Referente Payroll 1",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "descrizione_assunzioni",
      headerName: "Referente Assunzioni 1",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ccnl_grouped",
      headerName: "CCNL",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
      getApplyQuickFilterFn: undefined, //to remove this column from quick filter
    },
    {
      field: "data_fine_elaborazioni",
      headerName: "Data fine elaborazioni",
      width: 150,
      flex: screenSize > "768" ? 1 : 0,
      headerAlign: "center",
      align: "center",
      getApplyQuickFilterFn: undefined, //to remove this column from quick filter
      renderCell: (params) =>
        params.row.data_fine_elaborazioni
          ? moment(params.row.data_fine_elaborazioni).format("DD/MM/YYYY")
          : "",
      valueGetter: (params) =>
        params.row.data_fine_elaborazioni
          ? moment(params.row.data_fine_elaborazioni).format("DD/MM/YYYY")
          : "",
    },
  ];

  const handleDownloadCcnl = async () => {
    try {
      let response = await api.get(`/ccnl/attivi`, { responseType: "blob" });
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "elenco_ccnl.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("documenti ok");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-between md:space-x-5 mb-2 gap-y-1">
          <div className="flex md:flex-nowrap md:w-fit w-full flex-wrap justify-center md:justify-start gap-x-2">
            <GridToolbarQuickFilter placeholder="Cerca..." />
          </div>
          <div className="flex justify-center md:justify-end md:mx-0 mx-auto gap-2">
            <Button
              className="success-button"
              size="md"
              text={"Scarica CCNL clienti"}
              icon={<HiMiniArrowDownTray className="button-icon" />}
              onClick={handleDownloadCcnl}
            />
            <Button
              className="success-button"
              size="md"
              text={"Esporta recap clienti"}
              icon={<HiMiniArrowDownTray className="button-icon" />}
              onClick={() => setOpenModal(true)}
            />
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  const loadAziende = async () => {
    const aziende = await api.get("/aziende");
    setAziende(aziende.data);
  };
  useEffect(() => {
    loadAziende();
  }, []);
  return (
    <>
      {openModal &&
        createPortal(
          <ExportCliente
            setOpenModal={setOpenModal}
            //codice_azienda={cliente.codice_univoco}
          />,
          document.getElementById("portalModal")
        )}
      <div className="page-container">
        <Modal
          isOpen={openDelete}
          closeButton={() => setOpenDelete(false)}
          headerText={`Eliminazione Cliente`}
          rightButtons={
            <>
              <Button
                className="success-button"
                size="md"
                text={"Conferma"}
                icon={<HiOutlineCheck className="button-icon" />}
                //onClick={handleSave}
              />
            </>
          }
          leftButtons={
            <>
              <Button
                className="error-button"
                size="md"
                text={"Annulla"}
                icon={<HiOutlineXMark className="button-icon" />}
                onClick={() => setOpenDelete(false)}
              />
            </>
          }
        >
          <h3 className="text-center">
            Sicuro di voler eliminare: <b>{rowSel.codice_univoco}</b>?
          </h3>
        </Modal>

        <div className="w-full md:h-[70vh] h-[70vh] relative el-container">
          <DataGridPro
            density="compact"
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  ccnl_grouped: false,
                  data_fine_elaborazioni: false,
                },
              },
            }}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: LinearProgress,
              footer: CustomFooterPersonalized,
            }}
            disableRowSelectionOnClick={true}
            //loading={loading}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            rows={aziende}
            getRowId={(r) => r.codice_univoco}
          />
        </div>
      </div>
    </>
  );
};
