import { useState, useEffect } from "react";
import { TabPanel } from "../components/TabPanel";
import {
  MdBarChart,
  MdCheck,
  MdContactPhone,
  MdPayments,
} from "react-icons/md";
import { Button } from "../components/Button";
import useAxiosPrivate from "../hooks/useAxiosPrivate.js";
import { ConIncarico } from "./F24/ConIncarico";
import { SenzaIncarico } from "./F24/SenzaIncarico";
import api from "../api/api.js";
import { toast } from "react-toastify";
import moment from "moment";

import Modal from "../components/Modal.js";

export const F24 = ({ screenSize }) => {
  const [today, setToday] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [giornof24, setGiornoF24] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [tab, setTab] = useState(1);

  const loadGiornoF24 = async () => {
    try {
      const f24 = await api.post(`/f24/giorno`, {
        mese: today.split("-")[1],
        anno: today.split("-")[0],
        giorno: today.split("-")[2],
      });
      setGiornoF24(f24.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };
  const [tabs, setTabs] = useState([
    {
      icon: <MdBarChart size={30} className="tab-icon" />,
      text: "CON INCARICO",
      id: 1,
    },
    {
      icon: <MdPayments size={30} className="tab-icon" />,
      text: "SENZA INCARICO",
      id: 2,
    },
  ]);
  let content = [
    {
      child: (
        <ConIncarico
          screenSize={screenSize}
          f24={giornof24?.ID}
          loadGiornoF24={loadGiornoF24}
          giornof24={giornof24}
          dataF24={
            giornof24?.giorno + "/" + giornof24?.mese + "/" + giornof24?.anno
          }
        />
      ),
      id: 1,
    },
    {
      child: (
        <SenzaIncarico
          screenSize={screenSize}
          f24={giornof24?.ID}
          dataF24={
            giornof24?.giorno + "/" + giornof24?.mese + "/" + giornof24?.anno
          }
        />
      ),
      id: 2,
    },
  ];

  useEffect(() => {
    loadGiornoF24();
  }, [today]);

  return (
    <>
      <div className="mx-auto w-full page-container gap-4">
        <div className="el-container h-fit mb-4">
          <div className="w-full flex flex-col md:flex-nowrap flex-wrap justify-center mb-2 rounded-md ">
            <div className="text-lg font-semibold text-center">
              Giorno selezionato:{" "}
              <input
                type="date"
                id="start"
                name="start"
                value={today}
                onChange={async (e) => {
                  await setToday(e.target.value);
                }}
              />
            </div>
            <div className="text-lg font-semibold text-center">
              Prossima Scadenza :{" "}
              {`${giornof24?.giorno}/${
                giornof24?.mese <= 9 ? "0" + giornof24?.mese : giornof24?.mese
              }/${giornof24?.anno}`}
            </div>
          </div>
        </div>

        <TabPanel tab={tab} setTab={setTab} tabs={tabs} content={content} />

        {/* <div className="flex w-full min-h-fit justify-end">
        <Button
          className="success-button"
          text="salva"
          type="button"
          icon={<MdCheck className="button-icon" />}
          onClick={async () => {
            console.log("salva");
          }}
        />
      </div> */}
      </div>
    </>
  );
};
