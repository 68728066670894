import React, { useEffect, useState } from "react";
import api from "../../api/api";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import moment from "moment";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  AreaChart,
  Area,
  Cell,
  Label,
  LabelList,
} from "recharts";
import { HorizontalChart } from "../../components/HorizontalChart";
import logo from "../../assets/images/logo-sm.png";
import {
  MdChangeCircle,
  MdDownload,
  MdFileOpen,
  MdFilePresent,
  MdFirstPage,
  MdGavel,
  MdGroups,
  MdPages,
} from "react-icons/md";
import { Button } from "../../components/Button";
import { toast } from "react-toastify";
import {
  HiOutlineCheck,
  HiOutlinePencil,
  HiOutlinePlus,
  HiOutlineTrash,
  HiOutlineXMark,
  HiPlus,
} from "react-icons/hi2";
import Modal from "../../components/Modal";
import {
  DataGridPro,
  itIT,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { CustomFooterPersonalized } from "../../components/DataGrid/CustomFooterPersonalized";
export const ReferentiPaghe = ({ screenSize, f24, dataF24 }) => {
  const [utenti, setUtenti] = useState([]);
  const [utenteSel, setUtenteSel] = useState(null);
  const [data, setData] = useState(
    moment(new Date()).subtract(1, "months").format("YYYY-MM")
  );
  const [parcellazione, setParcellazione] = useState([]);
  const [parcellazioneAttive, setParcellazioneAttive] = useState([]);
  const [aziende, setAziende] = useState([]);
  const [aziendaSel, setAziendaSel] = useState(null);
  const [tipoOrdinamento, setTipoOrdinamento] = useState(true);
  const [tipoGrafico, setTipoGrafico] = useState(true);
  const [barSel, setBarSel] = useState(null);
  const [dettaglioReferente, setDettaglioReferente] = useState(null);
  const [meseSel, setMeseSel] = useState(null);

  useEffect(() => {}, [screenSize]);

  useEffect(() => {
    loadstats(data);
  }, [data]);

  useEffect(() => {
    if (dettaglioReferente) {
      document.getElementById("modal_referente").click();
    }
  }, [dettaglioReferente]);

  const loadstats = async (data) => {
    const parc = await api.get(`/parcellazione/statistiche/referenti_paghe`, {
      params: { data: data },
    });
    setParcellazione(parc.data);
    /*  if (!tipoGrafico) { //Commento perchè non devo più riordinare
      setParcellazione(
        parc.data.sort(
          (a, b) => b.cedolini_proporzionati - a.cedolini_proporzionati
        )
      );
    } else {
      setParcellazione(
        parc.data.sort((a, b) => b.cedolini_elaborati - a.cedolini_elaborati)
      );
    } */
  };

  const handleBarClick = async (el, index) => {
    const parc = await api.get(
      `/parcellazione/statistiche/referenti_paghe/${el.referente}`,
      { params: { data: data } }
    );
    setDettaglioReferente(parc.data);
    setBarSel(index);
  };

  const handleChangeView = () => {
    if (tipoOrdinamento) {
      //allora è alfabetico
      setParcellazione(
        parcellazione.sort((a, b) => a.nome.localeCompare(b.nome))
      );
    } else {
      setParcellazione(
        !tipoGrafico
          ? parcellazione.sort(
              (a, b) => b.cedolini_elaborati - a.cedolini_elaborati
            )
          : parcellazione.sort(
              (a, b) => b.cedolini_proporzionati - a.cedolini_proporzionati
            )
      );
    }

    setTipoGrafico(!tipoGrafico);
  };

  const CustomImageBar = ({ x, y, width, value }) => {
    const radius = 29; // Raggio del cerchio
    y = y + 20;
    return (
      <g>
        <circle cx={x + width} cy={y} r={radius} fill="#8884d8" />
        <clipPath id={`clip-${x}-${y}`}>
          <circle cx={x + width} cy={y} r={radius} />
        </clipPath>
        <image
          x={x + width - radius}
          y={y - radius}
          width={radius * 2}
          height={radius * 2}
          href={value}
          clipPath={`url(#clip-${x}-${y})`}
        />
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 flex flex-col">
          <div>
            <p className="font-bold">{`${label}`}</p>
          </div>
          <div>{`${payload[0].payload.totale_aziende} aziende gestite`}</div>
          <div>
            {payload[0].value +
              " Cedolini" +
              (tipoGrafico ? " Effettivi " : " Proporzionati ")}
          </div>
        </div>
      );
    }

    return null;
  };

  const handleDownloadReportMensile = async () => {
    try {
      let response = await api.post(
        `/parcellazione/statistiche/referenti_paghe/export`,
        { data: data },
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "referenti.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("download eseguito con successo");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  const handleChangeOrdinamento = () => {
    let new_ordinamento = !tipoOrdinamento;
    setTipoOrdinamento(new_ordinamento);
    if (new_ordinamento) {
      //allora è alfabetico
      setParcellazione(
        parcellazione.sort((a, b) => a.nome.localeCompare(b.nome))
      );
    } else {
      setParcellazione(
        tipoGrafico
          ? parcellazione.sort(
              (a, b) => b.cedolini_elaborati - a.cedolini_elaborati
            )
          : parcellazione.sort(
              (a, b) => b.cedolini_proporzionati - a.cedolini_proporzionati
            )
      );
    }
  };

  return (
    <div className="el-container h-fit ">
      <CustomModal
        dettaglio_referente={dettaglioReferente}
        referente={parcellazione[barSel]}
        data={data}
      />
      <div className="grid grid-cols-4 gap-2 h-full place-content-center">
        <div className="col-span-4 md:col-span-1">
          <Input
            type="month"
            name="Data"
            value={data}
            onChange={async (e) => {
              setData(e.target.value);
              //loadParcellazione(moment(e.target.value).format("YYYY-MM"),moment(dataA).format("YYYY-MM"))
            }}
          />
        </div>
        <div className="col-span-4 md:col-span-2 pl-3">
          <div className="flex flex-row gap-2">
            <Button
              text={tipoGrafico ? "Effettivi" : "Proporzionati"}
              className="success-button"
              size="md"
              type="button"
              icon={<MdChangeCircle className="button-icon" />}
              onClick={handleChangeView}
            />
            <Button
              text={"Report Referenti"}
              className="success-button"
              size="md"
              type="button"
              icon={<MdDownload className="button-icon" />}
              onClick={handleDownloadReportMensile}
            />
            <Button
              text={tipoOrdinamento ? "Alfabetico" : "Cedolino"}
              className="success-button"
              size="md"
              type="button"
              icon={
                tipoOrdinamento ? (
                  <MdGroups className="button-icon" />
                ) : (
                  <MdFileOpen className="button-icon" />
                )
              }
              onClick={handleChangeOrdinamento}
            />
          </div>
        </div>

        <div
          className="col-span-4 el-container h-[90vh]
      "
        >
          {/*  <ResponsiveContainer width="100%" height="100%">*/}
          <div style={{ height: "90vh", overflow: "auto", width: "100wv" }}>
            <BarChart
              barGap={40}
              barCategoryGap={20}
              layout="vertical"
              width={window.innerWidth - 200}
              height={parcellazione.length * 60}
              data={parcellazione}
              margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis type="category" dataKey="nome" />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                barSize={40}
                dataKey={
                  tipoGrafico ? "cedolini_elaborati" : "cedolini_proporzionati"
                }
                fill="#2265B1"
                onClick={handleBarClick}
              >
                <LabelList
                  dataKey="pic"
                  content={(props) => (
                    <CustomImageBar
                      x={props.x}
                      y={props.y}
                      width={props.width}
                      value={props.value ? props.value : logo}
                    />
                  )}
                />
                {parcellazione.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    fill={index === barSel ? "#8dc4eb" : "#2265B1"}
                    key={`cell-${index}`}
                    onClick={async () => await handleBarClick}
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomModal = ({ dettaglio_referente, referente, data }) => {
  const [open, setOpen] = useState(false);
  const [currData, setCurrdata] = useState(false);
  const [selectedF24, setSelectedF24] = useState(null);

  const handleClickOpen = async () => {
    setCurrdata(ManageData(data));
    setOpen(true);
  };

  const columns = [
    {
      field: "codice_univoco_azienda",
      headerName: "Codice univoco Azienda",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ragione_sociale",
      headerName: "Ragione Sociale",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cedolini_totali_mese",
      headerName: "Cedolini Elaborati",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "presenze_differite",
      headerName: "Presenza Differite",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "consegna_no_round",
      headerName: "Scadenza Consegna Paghe",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "caricamento_presenze",
      headerName: "Modalità caricamento presenze",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ccnl",
      headerName: "CCNL",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <>
      <Button
        id="modal_referente"
        className="hidden"
        text="Aggiungi"
        type="button"
        icon={<HiPlus className="button-icon" />}
        onClick={async () => {
          await handleClickOpen();
        }}
      />

      <Modal
        isOpen={open}
        closeButton={() => setOpen(false)}
        headerText={currData}
      >
        <div className="grid grid-cols-2 gap-4 justify-items-stretch items-center">
          <div className="col-span-2 md:col-span-1 justify-self-center">
            <img
              alt="user 5"
              src={referente?.pic ? referente.pic : logo}
              className="relative inline-block h-32 w-32 rounded-full border-2 border-white object-cover object-center hover:z-10 focus:z-10"
            />
          </div>

          <div className="col-span-2 md:col-span-1 text-lg">
            <p className="font-bold">{referente ? referente.nome : ""}</p>
            <p>
              {" "}
              Aziende Gestite: {referente ? referente.totale_aziende : ""}{" "}
            </p>
            <p>
              {" "}
              Cedolini Elaborati:{" "}
              {referente ? referente.cedolini_elaborati : ""}{" "}
            </p>
            <p>
              {" "}
              Cedolini In Proporzione:{" "}
              {referente ? referente.cedolini_proporzionati : ""}{" "}
            </p>
          </div>
        </div>

        <div className="col-span-2 mt-5 h-[40vh]">
          <DataGridPro
            density="compact"
            slots={{
              //toolbar: CustomToo,
              //loadingOverlay: LinearProgress,
              footer: CustomFooterPersonalized,
            }}
            slotProps={{
              footer: {
                fileName: referente?.nome + " - " + currData,
              },
            }}
            //loading={loading}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            rows={dettaglio_referente}
            getRowId={(r) => r.codice_univoco_azienda}
          />
        </div>
      </Modal>
    </>
  );
};

const ManageData = (data) => {
  const [anno, mese] = data.split("-");
  const mesi = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  return mesi[parseInt(mese) - 1] + " " + anno;
};
