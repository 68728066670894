import { useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { CustomCheckbox } from "../../components/Checkbox";
import { Select } from "../../components/Select";
import { PrincipalButton } from "../../components/PrincipalButton";
import { Button } from "../../components/Button";
import { HiOutlineCheck } from "react-icons/hi2";
import api from "../../api/api";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import { knowAcces } from "../../utils/utils";

export const AssunzioniClienti = ({
  cliente,
  handleChange,
  handleSave,
  handleChangeSelect,
  handleChangeCheckbox,
}) => {
  const [ccnl, setCcnl] = useState([]);
  const [tipologie, setTipologie] = useState([]);
  /* useEffect(() => {
    (() => {
      console.log(cliente);
    })();
  }, []); */

  const loadTipologie = async () => {
    try {
      const tip = await api.get(`/tipologia_servizio_assunzioni`);
      setTipologie(tip.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  const loadCCnl = async () => {
    try {
      const ccnl = await api.get(`/ccnl/${cliente.codice_univoco}`);
      setCcnl(ccnl.data);
    } catch {
      toast.error("Errore durante il caricamento");
    }
  };

  useEffect(() => {
    loadCCnl();
    loadTipologie();
  }, []);

  const handleSaveAssunzioni = async () => {
    try {
      if (ccnl.length > 0) {
        let savePayroll = await api.put("/ccnl", { ccnl });
      }

      handleSave();
    } catch {
      toast.error("Errore durante il salvataggio ");
    }
  };

  /* useEffect(() => {
    (() => {
      console.log(cliente);
    })();
  }, []); */

  return (
    <>
      <PrincipalButton
        button={
          <Button
            className="principal-button z-[110]"
            text="Salva"
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleSaveAssunzioni();
            }}
          />
        }
      />
      <div className="grid grid-cols-2 gap-2 w-full el-container">
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"Gruppo Aziende"}
            name="gruppo_cliente"
            onChange={(e) => handleChange(e)}
            value={cliente.gruppo_cliente}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"referente Assunzioni"}
            name="referente_attivo_assunzioni"
            value={cliente.descrizione_assunzioni}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"CDL di riferimento"}
            name="cdl_di_riferimento"
            value={cliente.cdl_di_riferimento}
            disabled={true}
          />
        </div>
        {/* <div className="col-span-2 md:col-span-1">
          <Input
            type="text"
            label={"Referente Assunzioni 2"}
            name="referente_assunzioni_2"
            onChange={(e) => handleChange(e)}
            value={cliente.referente_assunzioni_2}
            disabled={false}
      /> 
        </div>*/}
        <div className="col-span-2 md:col-span-1">
          <Select
            type="text"
            label={"tipologia Servizio"}
            name="tipologia_servizio_assunzioni"
            //onChange={(e) => handleChange(e)}
            value={cliente.tipologia_servizio_assunzioni}
            onChange={(e) =>
              handleChangeSelect(e, "tipologia_servizio_assunzioni")
            }
            placeholder="SELEZIONA..."
            options={tipologie}
            disabled={!knowAcces(["admin", "team_leader", "assunzioni"])}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <Input
            type="number"
            label={"n° Medio Cedolini Trimestrale"}
            name="media_cedolini"
            value={cliente.media_cedolini}
            disabled={true}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <CustomCheckbox
            label="PUBBLICAZIONE CCNL"
            name="pubblicazione_ccnl"
            checked={cliente.pubblicazione_ccnl}
            onClick={(e) => handleChangeCheckbox(e, "pubblicazione_ccnl")}
            value={cliente.pubblicazione_ccnl === 1}
            disabled={!knowAcces(["admin", "team_leader", "assunzioni"])}
          />
        </div>

        <div className="col-span-2 grid grid-cols-3 gap-2 w-full ">
          {ccnl.map((el, index) => (
            <>
              <div className="col-span-3 md:col-span-1">
                <Input
                  type="text"
                  label={"ccnl " + (index + 1)}
                  name={"ccnl " + (index + 1)}
                  value={
                    el.codice_contratto +
                    " - " +
                    el?.descrizione_ccnl +
                    " - " +
                    el?.codice_cnel
                  }
                  disabled={true}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Input
                  type="text"
                  label={"settore Ccnl " + (index + 1)}
                  name={"settoreCcnl" + (index + 1)}
                  value={
                    el.codice_settore && el.descrizione_settore_ccnl
                      ? el.codice_settore + " - " + el.descrizione_settore_ccnl
                      : ""
                  }
                  disabled={true}
                />
              </div>
              <div className="col-span-3 md:col-span-1">
                <Input
                  type="text"
                  label={"raccordo Ccnl " + (index + 1) + " IPSOA"}
                  name="raccordoCcnl1"
                  value={el.ipsoa}
                  disabled={true}
                />
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
