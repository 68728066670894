import { useEffect, useState, useContext } from "react";
import { Button } from "../../components/Button";
import { HiArrowDownTray, HiOutlineCheck } from "react-icons/hi2";
import api from "../../api/api";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import { CustomCheckbox } from "../../components/Checkbox";
import { Input } from "../../components/Input";
import moment from "moment";

const defaultColonne = [
  //tutte le colonne possibili
  {
    categoria: "Dashboard",
    includi: 1,
    campi: [
      {
        name: "Data Cessazione",
        column_name: "t.data_cessazione",
        includi: 1,
        ordine: 23,
      },
      {
        name: "Data Fine Elaborazioni",
        column_name: "data_fine_elaborazioni",
        includi: 1,
        ordine: 24,
      },
      {
        name: "Data Inizio Elaborazioni",
        column_name: "data_inizio_elaborazioni",
        includi: 1,
        ordine: 22,
      },
      {
        name: "Motivo Fine Servizio",
        column_name: "tipologia_fine_servizio.Descrizione",
        includi: 1,
        ordine: 4,
        ordine: 25,
      },
      { name: "CCNL", column_name: "ccnl_grouped", includi: 1, ordine: 1 },
      {
        name: "Codice Fiscale",
        column_name: "codice_fiscale",
        includi: 1,
        ordine: 14,
      },
      {
        name: "Media Cedolini Trimestrale",
        column_name: "media_cedolini",
        includi: 1,
        ordine: 3,
      },
    ],
  },
  {
    categoria: "Payroll",
    includi: 1,
    campi: [
      {
        name: "Gruppo Aziende",
        column_name: "gruppo_cliente",
        includi: 1,
        ordine: 1,
      },
      {
        name: "Referente Paghe",
        column_name:
          "IF(referente_payroll_2 IS NOT NULL,CONCAT(utenti_con_resp.nome,' - ',ut_payroll_2.nome),utenti_con_resp.nome)",
        ordine: 2,
        includi: 1,
      },
      {
        name: "Team Leader",
        column_name: "utenti_con_resp.team_leader_nome",
        includi: 1,
        ordine: 8,
      },

      {
        name: "CDL di Riferimento",
        column_name: "cdl_di_riferimento",
        includi: 1,
        ordine: 9,
      },

      {
        name: "Scadenza Consegna Paghe",
        column_name: "TRUNCATE(scadenza_consegna_paghe,0)",
        includi: 1,
        ordine: 5,
      },
      {
        name: "Caricamento Presenze",
        column_name: "caricamento_presenze",
        includi: 1,
        ordine: 6,
      },
      {
        name: "Autorizzazione Stampa LUL",
        column_name: "autorizzazione_stampa_lul",
        includi: 1,
        ordine: 12,
      },
      {
        name: "Pubblicazione Doc Dipendenti",
        column_name: "pubblicazione_doc_dipendenti",
        includi: 1,
        ordine: 7,
      },
      {
        name: "Presenze Differite",
        column_name: "presenze_differite",
        includi: 1,
        ordine: 4,
      },
    ],
  },
  {
    categoria: "Assunzioni",
    includi: 1,
    campi: [
      {
        name: "Referente Assunzioni",
        column_name: "descrizione_assunzioni",
        includi: 1,
        ordine: 10,
      },
      {
        name: "CDL di Riferimento",
        column_name: "cdl_di_riferimento",
        includi: 1,
        ordine: 9,
      },

      {
        name: "Tipologia Servizio Assunzioni",
        column_name: "tipologia_servizio_assunzioni.descrizione",
        includi: 1,
        ordine: 11,
      },
      {
        name: "Pubblicazione CCNL",
        column_name:
          "IF(pubblicazione_ccnl IS NULL OR pubblicazione_ccnl =0,'N','S')",
        includi: 1,
        ordine: 11,
      },
    ],
  },
  {
    categoria: "Recapiti/altri servizi",
    includi: 1,
    campi: [
      { name: "PEC", column_name: "pec", includi: 1, ordine: 13 },
      {
        name: "Cassetto Fiscale",
        column_name: "cassetto_fiscale",
        includi: 1,
        ordine: 15,
      },
      {
        name: "Data inizio Incarico (F24)",
        column_name: "inizio_incarico",
        includi: 1,
        ordine: 16,
      },
      {
        name: "Data Fine Incarico (F24)",
        column_name: "fine_incarico",
        includi: 1,
        ordine: 17,
      },
      {
        name: "Commercialista",
        column_name:
          "CONCAT(descrizione_commercialista,' ',IF(referenti_accounting.name IS NOT NULL,referenti_accounting.name,''))",
        includi: 1,
        ordine: 18,
      },
      /*{
        name: "Referente Miller Accounting",
        column_name: "referenti_accounting.name",
        includi: 1,
      },*/
      {
        name: "Tipologia Servizio Accounting",
        column_name: "tipologia_servizio_altro.descrizione",
        includi: 1,
        ordine: 19,
      },
      {
        name: "Invio Mensile DM10",
        column_name: "IF(invio_mensile IS NULL OR invio_mensile =0,'N','S')",
        includi: 1,
        ordine: 20,
      },
      {
        name: "Cliente Miller Sicurezza",
        column_name: "cliente_miller_sicurezza",
        includi: 1,
        ordine: 21,
      },
    ],
  },
  {
    categoria: "Fatturazione",
    includi: 0,
    campi: [
      {
        name: "Codice Parcellazione",
        column_name: "codice_parcellazione",
        includi: 1,
      },
      {
        name: "Tipologia Fatturazione",
        column_name: "tipologia_fatturazione",
        includi: 1,
      },
      {
        name: "Codice Fatturazione",
        column_name: "codice_fatturazione",
        includi: 1,
      },

      {
        name: "Presenze HR Portal",
        column_name: "presenze_hr_portal",
        includi: 1,
      },
      { name: "Presenze Manuali", column_name: "presenze_manuali", includi: 1 },
      {
        name: "Pubblicazione CCNL",
        column_name: "pubblicazione_ccnl",
        includi: 1,
      },

      {
        name: "Pubblicazione Documentale",
        column_name: "pubblicazione_documentale",
        includi: 1,
      },
      {
        name: "Pubblicazione Documentale Dipendenti",
        column_name: "pubblicazione_documentale_dipendenti",
        includi: 1,
      },
      { name: "Alfieri", column_name: "alfieri", includi: 1 },

      { name: "Ambiente", column_name: "ambiente", includi: 1 },

      {
        name: "Fondi Assicurazione Sanitaria",
        column_name: "fondi_assicurazione_sanitaria",
        includi: 1,
      },

      { name: "Cassa Edile", column_name: "cassa_edile", includi: 1 },
    ],
  },
];

const ExportCliente = ({ setOpenModal, codice_azienda }) => {
  const [colonne, setColonne] = useState([]);
  const [all, setAll] = useState(true);
  const [date, setDate] = useState(null);

  useEffect(() => {
    setColonne(defaultColonne);
    setDate(null);
  }, []); // questa verrà lanciata sempre all'apertura

  const handleChangeCheckbox = (e, index, index_campo) => {
    let c = [...colonne];

    c[index].includi = e.target.checked ? 1 : 0;
    setColonne(c);
  };

  const handleExport = async () => {
    try {
      let response = await api.post(
        `/aziende/export`,
        {
          configurazione: colonne,
          azienda: all ? null : codice_azienda,
          data_fine_elaborazione: date,
        },
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "" })
      ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "aziende.xlsx");
      document.body.appendChild(link);
      link.click();
      toast.success("download eseguito con successo");
    } catch {
      toast.error("errore nel download dei documenti");
    }
  };

  const handleChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <>
      <Modal
        isOpen={true}
        closeButton={() => setOpenModal(false)}
        headerText={"ESPORTA ANAGRAFICHE CLIENTI"}
        leftButtons={
          <Button
            className="principal-button z-[110]"
            text={"Esporta"}
            type="button"
            icon={<HiOutlineCheck className="button-icon" />}
            onClick={async () => {
              handleExport();
            }}
          />
        }
      >
        <div className="grid grid-cols-4">
          {codice_azienda != null && (
            <>
              <div className="col-span-4">
                <CustomCheckbox
                  label={"Esporta tutti i clienti"}
                  checked={all}
                  onClick={(e) => setAll(!all)}
                  //onClick={(e) => filter(e, "assegnati")}
                  value={all}
                />
              </div>

              <div className="col-span-4">
                <hr></hr>
              </div>
            </>
          )}
          {colonne.map((el, index) => {
            return (
              <div className="col-span-1">
                <CustomCheckbox
                  label={el?.categoria}
                  name={el?.column_name}
                  checked={el?.includi}
                  onClick={(e) => handleChangeCheckbox(e, index)}
                  //onClick={(e) => filter(e, "assegnati")}
                  value={el?.includi}
                  disabled={false}
                />
              </div>
            );
          })}
          <div className="col-span-1">
            <Input
              type="date"
              label={"data fine elaborazioni da escludere"}
              onChange={(e) => handleChange(e)}
              value={date ? moment(date).format("YYYY-MM-DD") : null}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExportCliente;
